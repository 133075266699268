import React, { useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './UserReportsModal.scss';
import { generateBem } from '../../utils/generateBem';
import { Button, MyModal } from '../common';
import { Field, Form, Formik } from 'formik';
import { StyledCheckbox } from '../formik';
import { useDispatch } from 'react-redux';
import { setNotice } from '../../redux/modules/UI/actions';
import InputStyledCheckbox from '../common/InputStyledCheckbox/InputStyledCheckbox';
import { MultiToggle } from '../inputs';
import SavviLoading from '../SavviLoading/SavviLoading';
import {
  createSharedReport,
  updateSharedReport,
} from '../../redux/modules/Company/operations';

const bem = generateBem('userReportsModal');

function initVals(resourcesList, restrictedResources) {
  let resources = {};

  resourcesList.forEach(item => {
    let isHidden = false;
    if (restrictedResources.length > 0) {
      isHidden = restrictedResources.findIndex(id => item.resourceId === id) !== -1;
    }
    resources[item.resourceId] = isHidden ? '0' : '1';
  });
  return { resources };
}

const UserReportsModal = ({
  handleClose,
  isOpen,
  json,
  report,
  resourcesList,
  reports_feature_type_id,
}) => {
  const dispatch = useDispatch();
  const { resource_label } = json || {};
  const { id: reportId, is_active, metadata: { resource_id = [] } = {} } = report || {};

  const [isActive, setIsActive] = useState(!!is_active);

  const handleSubmit = formikValues => {
    let resource_id = [];
    Object.entries(formikValues.resources).forEach(([key, val]) => {
      if (val === '0') {
        resource_id.push(key);
      }
    });

    if (!reportId) {
      dispatch(
        createSharedReport(null, { feature_type_id: reports_feature_type_id }),
      ).then(payload => {
        dispatch(setNotice('Report Sharing Updated'));
        handleClose();
      });
    }

    dispatch(
      updateSharedReport(null, reportId, {
        is_active: isActive,
        metadata: { resource_id },
      }),
    ).then(p => {
      dispatch(setNotice('Report Sharing Updated'));
      handleClose();
    });
  };

  return (
    <MyModal
      className={bem('')}
      overlayClassName={bem('overlay')}
      isOpen={isOpen}
      onRequestClose={handleClose}
    >
      <div className={bem('top')}>
        <h1>Manage {resource_label} Report Sharing </h1>
        <FontAwesomeIcon className={bem('exit')} onClick={handleClose} icon="times" />
      </div>
      <div className={bem('body')}>
        <MultiToggle
          name="info-sidebar-task-actions"
          label={`Is This Report Showing in User Dashboards?`}
          mode={isActive ? 'show' : 'hide'}
          options={[
            { label: 'Showing', mode: 'show', value: 'show' },
            { label: 'Hidden', mode: 'hide', value: 'hide' },
          ]}
          onClick={selectedOption => {
            const { mode } = selectedOption;
            setIsActive(mode === 'show');
          }}
        />
        {!isOpen && <SavviLoading />}
        {!!isOpen && (
          <Formik
            initialValues={initVals(resourcesList, resource_id)}
            onSubmit={handleSubmit}
          >
            {formikProps => (
              <LedgerForm
                formikProps={formikProps}
                isActive={isActive}
                resourcesList={resourcesList}
                handleClose={handleClose}
              />
            )}
          </Formik>
        )}
      </div>
    </MyModal>
  );
};

function getCheckboxMeta(vals) {
  let isAllChecked = true;
  let isAllUnchecked = true;
  vals.forEach(val => {
    if (val === '1') {
      isAllUnchecked = false;
    } else {
      isAllChecked = false;
    }
  });
  if (isAllChecked) {
    return 'checked';
  } else if (isAllUnchecked) {
    return 'unchecked';
  } else {
    return 'indeterminate';
  }
}

const LedgerForm = ({ isActive, formikProps, resourcesList, handleClose }) => {
  const { values, setValues } = formikProps;

  const valuesMeta = useMemo(() => {
    return {
      resources: getCheckboxMeta(Object.values(values.resources)),
    };
  }, [values]);

  let totalSelected = Object.values(formikProps.values.resources).filter(
    item => item === '1',
  ).length;

  const handleResourcesCheckboxes = () => {
    let resources = {};
    if (valuesMeta.resources === 'checked' || valuesMeta.resources === 'indeterminate') {
      Object.keys(values.resources).forEach(key => {
        resources[key] = '0';
      });
    } else {
      Object.keys(values.resources).forEach(key => {
        resources[key] = '1';
      });
    }
    setValues({ featureTypes: values.featureTypes, resources });
  };

  return (
    <Form>
      <InputStyledCheckbox
        checked={valuesMeta.resources === 'checked'}
        isDisabled={!isActive}
        indeterminate={valuesMeta.resources === 'indeterminate'}
        name="data-ledger-resources-all"
        onClick={handleResourcesCheckboxes}
        parentStyle={bem('checkboxHeader')}
        label={`Select which users can view this report (${totalSelected} of ${resourcesList.length}):`}
        value={valuesMeta.resources === 'checked'}
      />
      {resourcesList.map((item, index) => (
        <Field
          key={`ledger-resource-${index}-${item.resourceName}`}
          name={`resources.${item.resourceId}`}
          isDisabled={!isActive}
          component={StyledCheckbox}
          label={item.resourceName}
        />
      ))}
      <div className={bem('actions')}>
        <Button buttonType="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit">Save Changes</Button>
      </div>
    </Form>
  );
};

export default UserReportsModal;
