// import { refreshCompanyToken } from '../modules/Company/operations';
import { get } from 'lodash-es';
import { setNotice } from '../modules/UI/actions';
import { setCompanyTokenRefreshing } from '../modules/Company/actions';
let Errors = {
  getMessage: err => {
    let localization = Errors.codes[err.code];
    if (localization) {
      return localization;
    }

    console.error(err);
    if (err.message) {
      return err.message;
    }
    return false;
  },
  codes: {
    E_SESSION_INVALID: 'Missing or expired session. Please login to continue.',
    INVALID_JWT:
      'Your auth token is expired. Please refresh your page to be assigned a new token.',
    DEVELOPER_ERROR:
      "We're experiencing issues related to your session, please refresh this window.",
  },
};

const PayloadHandler =
  ({ dispatch, getState }) =>
  next =>
  async action => {
    const {
      Company: { isCompanyTokenRefreshing },
    } = getState();
    if ((action.type || '').endsWith('FAILED')) {
      // this path mainly occurs on 404
      if (action.payload?.response?.error) {
        action.payload = action.payload.response.error;
      } else if (action.payload.response) {
        action.payload = action.payload.response;
      } else {
        action.payload = { ...action, message: 'There was a network error.' };
      }
      let msg = Errors.getMessage(action.payload);
      if (
        action.payload.code === 'INVALID_JWT' &&
        (action.payload.details || []).join().includes('jwt.claims.exp') &&
        !isCompanyTokenRefreshing
      ) {
        console.info('workspace access token has expired, will refresh.');

        dispatch(setNotice('workspace access token has expired, will refresh.'));
        window.location.reload();
      }
      if (msg) {
        action.payload.replaced_msg = action.payload.msg;
        action.payload.message = msg;
      }
      console.error(msg + '*');
      if (action.payload.status !== 400) {
        if (action.payload._id) {
          console.info(
            '*To help us better understand and help you with this error, please share this error id to our support chat:',
            action.payload._id,
          );
        } else {
          console.info(
            '*If this error looks to be causing you issues, please reach out to our chat support for help.',
          );
        }
      }
    } else if (action.type.endsWith('SUCCESS')) {
      if (action.payload.payload && action.payload.success) {
        action.payload = action.payload.payload;
      }
    } else if (action.error) {
      action.type = action.type.replace(/(?:REQUEST|SUCCESS)$/, 'FAILED');
    }

    return await next(action);
  };

export const myInterceptor =
  ({ dispatch, getState }) =>
  next =>
  async action => {
    let {
      Auth: {
        account: { accountId, exp },
        isInitLoading,
        user: { exp: userTokenExp },
      },
      Company: { isCompanyTokenRefreshing },
    } = getState();
    const endpoint = get(action, '[@@redux-api-middleware/RSAA].endpoint', '');
    const isCompanyEndpoint =
      endpoint &&
      !endpoint.includes('/authn/') &&
      !endpoint.includes('/public/') &&
      !endpoint.includes('/unlisted/') &&
      !endpoint.includes('/guest/') &&
      !endpoint.includes('/remote-login/') &&
      !endpoint.includes('/user/');

    const currentDate = Date.now() / 1000 - 600;

    if (
      endpoint &&
      (endpoint.includes('authn') || endpoint.includes('user')) &&
      !isInitLoading &&
      currentDate > userTokenExp
    ) {
      console.info('user access token is expired, you will now be logged out.');
      dispatch(setNotice('Your Auth Token is expired, please log back in to continue.'));
      localStorage.removeItem('id_tokens');
      localStorage.removeItem('id_token');
      localStorage.removeItem('storedLocation');
      localStorage.removeItem('hide_verify');
      window.location.reload();
    } else if (
      isCompanyEndpoint &&
      !isInitLoading &&
      !isCompanyTokenRefreshing &&
      accountId &&
      currentDate > exp
    ) {
      console.info('company access token is expired, will refresh.');
      await dispatch(setCompanyTokenRefreshing(true));
      await dispatch(setNotice('company access token is expired, will refresh.'));
      window.location.reload();
      // await dispatch(refreshCompanyToken(accountId, null, true)).then(e => {
      //   window.location.reload();
      // });
      // const tokenResponse = await dispatch(refreshCompanyToken(accountId, null, true));
      // if (!!tokenResponse?.access_token) {
      //   action[
      //     '@@redux-api-middleware/RSAA'
      //   ].headers.Authorization = `Bearer ${tokenResponse.access_token}`;
      // }
    }
    return await next(action);
  };

export default PayloadHandler;
