import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
// import { useLocation } from 'react-router';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Button, DropdownAction, MyModal, TooltipAction } from '../../components/common';
import {
  getFaqsForProjectView,
  getGroupedTransactionsWithDocumentsForProjectView,
  getLearnMoreMetaForProjectView,
  ProjectCounsel,
} from '../../redux/modules/Project/selectors';
// import { getFaqsFromTransaction } from '../../redux/modules/Transaction/selectors';
import { generateBem } from '../../utils/generateBem';
import './PortalView.scss';
import {
  addRoomCode,
  // fetchRoomAccessLog,
  fetchRoomCodes,
  fetchRoomHistory,
  fetchRoomLinks,
  fetchRoomDocuments,
  fetchRoomParticipants,
  updateRoom,
  updateRoomDocument,
  swapRoomDocuments,
  deleteRoomDocument,
  createRoomHistory,
} from '../../redux/modules/Data/operations';
import {
  getRoomDocuments,
  getRoomLinks,
  getRoomSharingMeta,
} from '../../redux/modules/Data/selectors';
import { setNotice } from '../../redux/modules/UI/actions';
import handlePath from '../../utils/handlePath';
import { getProjectDocuments } from '../../redux/modules/Document/operations';
import ContentPreview from 'box-ui-elements/es/elements/content-preview';
import { BoxToken } from '../../redux/modules/Cabinet/selectors';
import { ReactPDFViewer } from '../../components/FilePreviewer/FilePreviewer';
import DocumentPreview from '../../components/DocumentPreview';
import dayjs from 'dayjs/esm';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import RoomUserHistoryModal from '../../components/RoomUserHistoryModal';
import { RoomStandingFormsModal } from '../Room/RoomSettings';
import {
  getNonOpenForms,
  getReviewValues,
  IsWorkflowCounselShowing,
} from '../../redux/modules/Transaction/selectors';
import WorkbenchInfoModal from '../../components/WorkbenchInfoModal';
import {
  getTransactionQuestionnaire,
  updateTransaction,
} from '../../redux/modules/Transaction/operations';
import UseWindowSize from '../../utils/UseWindowSize';
import {
  getAccountProject,
  performTaskAction,
  updateProject,
  updateTaskVariant,
} from '../../redux/modules/Project/operations';
import { BrandMeta, SearchInput } from '../../redux/modules/UI/selectors';
import { someValuesEveryString } from '../../utils/FilterTools';
import { requestHeaders } from '../../redux/utils/api';
import { DocumentsByProject } from '../../redux/modules/Document/selectors';
import { Form, Formik } from 'formik';
import InputStyledCheckbox from '../../components/common/InputStyledCheckbox';
import { PDFViewer } from '../../components/OverviewCard';
import AlertModal from '../../components/AlertModal';
import { ReactComponent as DocPreviewSVG } from '../../assets/images/doc_preview.svg';
import { InputStyledTextField } from '../../components/inputs';
import PandaDocStatusDict from '../../utils/PandaDocStatusDict';
import getPortalType from '../../utils/getPortalType';

dayjs.extend(localizedFormat);

const bem = generateBem('portalView');

const PortalView = ({ params = {} }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { width } = UseWindowSize();
  const { companyId, moduleId = 2, projectId, transactionId } = params;
  const searchInput = useSelector(SearchInput);
  const project = useSelector(state =>
    getGroupedTransactionsWithDocumentsForProjectView(state, projectId),
  );
  const brandMeta = useSelector(BrandMeta);
  const {
    rooms = [],
    groupedTransactions = [],
    transactions = [],
    label: projectLabel,
    template: { actions = [] } = {},
  } = project;
  const { id: templateId } = actions[0] || {};
  const startedActionIndex = transactions.findIndex(
    e => !!e.open && e.template.id === templateId,
  );
  const { id } = transactions[startedActionIndex] || {};
  const projectRoom = rooms.find(e => !e.group) || {};
  const learnMoreMeta = useSelector(state =>
    getLearnMoreMetaForProjectView(state.Project, projectId),
  );
  const roomSharingMeta = useSelector(State => getRoomSharingMeta(State, projectRoom.id));

  const filteredGroups = groupedTransactions.filter(task =>
    someValuesEveryString(task, [searchInput]),
  );

  let assessmentIndex = transactions.findIndex(v => !v.open && v.template.is_assessment);
  if (assessmentIndex === -1) {
    assessmentIndex = 0;
  }
  const {
    allow_reopen,
    label: assessmentLabel,
    id: assessmentId,
  } = transactions[assessmentIndex] || {};

  const inputEl = useRef(null);
  const [inputLabel, updateInputLabel] = useState(projectLabel || '');
  const [isEditable, setEditable] = useState(false);

  useEffect(() => {
    if (isEditable === true) {
      inputEl.current.focus();
    }
  }, [isEditable]);
  const [isProjectDocsFetched, setIsProjectDocsFetched] = useState(false);

  useEffect(() => {
    if (!isProjectDocsFetched) {
      setIsProjectDocsFetched(true);
      dispatch(getProjectDocuments(projectId));
      dispatch(getTransactionQuestionnaire(transactionId));
    }
  }, [dispatch, projectId, isProjectDocsFetched, transactionId]);

  const projectTemplateId = project.template?.id;

  const portalType = getPortalType(projectTemplateId);

  const portalTypePlural = getPortalType(projectTemplateId, true);
  const isGroupsEmpty = filteredGroups.length < 2;

  const handleUpdateProjectName = ev => {
    const trimmedLabel = (ev.target.value + '').trim();
    if (trimmedLabel.length > 0 && trimmedLabel !== projectLabel.trim()) {
      dispatch(updateProject({ label: trimmedLabel }, projectId)).then(
        payload => {
          updateInputLabel(trimmedLabel);
          setEditable(false);
        },
        error => {
          updateInputLabel(projectLabel);
          setEditable(false);
        },
      );
    } else {
      updateInputLabel(projectLabel);
      setEditable(false);
    }
  };

  const [isReopenShowing, setIsReopenShowing] = useState(false);

  const handleReopenAssessment = ev => {
    dispatch(performTaskAction('reopen', assessmentId, projectId)).then(
      e => {
        setIsReopenShowing(false);
        push(
          handlePath(
            {
              pathname: `/workbench/${moduleId}/${projectId}/${assessmentId}/task-view`,
              state: { isReopening: true },
            },
            companyId,
          ),
        );
      },
      error => setIsReopenShowing(false),
    );
  };

  return (
    <div className={bem('container')}>
      <h1 className={bem('title')}>
        {isEditable && (
          <textarea
            ref={inputEl}
            onBlur={handleUpdateProjectName}
            onChange={e => {
              updateInputLabel(e.target.value);
            }}
            value={inputLabel}
          />
        )}
        {!isEditable && inputLabel}
        <Button
          buttonType="icon"
          size="sm"
          className={bem('edit-icon')}
          onClick={() => setEditable(!isEditable)}
        >
          <FontAwesomeIcon icon={['fal', 'edit']} className="text-muted" />
        </Button>
        <DropdownAction size="sm">
          {learnMoreMeta.isLearnMoreShowing && (
            <Button
              buttonType="secondary"
              onClick={e => e.stopPropagation()}
              href={learnMoreMeta.learnMoreUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </Button>
          )}
          <Button
            buttonType="secondary"
            isDisabled={!allow_reopen}
            isWarning
            onClick={ev => setIsReopenShowing(true)}
            // onClick={e => {
            //   handleReopenAssessment('reopen', transactionId, label, is_assessment);
            // }}
          >
            Restart Assessment
          </Button>
        </DropdownAction>
      </h1>
      <div className={bem('main')}>
        <div className={bem('section')}>
          <div className={bem('nav')}>
            <NavLink
              activeClassName={bem('nav-link--selected')}
              className={bem('nav-link')}
              to={handlePath(
                `/workbench/${moduleId}/${projectId}/${transactionId}/portal`,
                companyId,
              )}
              exact
            >
              <h4>
                {portalTypePlural}
                {filteredGroups.length > 2 && ` (${filteredGroups.length - 1})`}
              </h4>
            </NavLink>
            {/* <NavLink
              activeClassName={bem('nav-link--selected')}
              className={bem('nav-link')}
              to={`${match.url}/overview`}
              exact
            >
              <h4>Related Projects</h4>
            </NavLink> */}
          </div>
          <div className={bem('body')}>
            {isGroupsEmpty && (
              <div className={bem('empty')}>
                <h2>
                  {/* <FontAwesomeIcon className={bem('empty-info')} icon="circle-info" /> */}
                  No {portalTypePlural} added yet.
                </h2>
                <h3>
                  <Button
                    isFlat
                    size="xs"
                    to={handlePath(
                      `/workbench/${moduleId}/${projectId}/${id || '0'}/add`,
                      companyId,
                    )}
                  >
                    Add {portalType}
                  </Button>
                  {' - '}If you already have the basic info you need about a(n){' '}
                  {portalType} go ahead and take this action to fill out an initial form.
                  This will generate tasks and a deal room based around that {portalType}.
                </h3>
                <h3>
                  <Button
                    buttonType="secondary"
                    isFlat
                    size="xs"
                    to={handlePath(
                      `/workbench/${moduleId}/${projectId}/${id || '0'}/batch-upload`,
                      companyId,
                    )}
                  >
                    Batch Upload {portalTypePlural}
                  </Button>
                  {` - If you would rather batch upload multiple ${portalType}'s basic info at once then you can input their data into a csv template we provide to automatically generate tasks and a deal room for each ${portalType}.`}
                </h3>
                {!!roomSharingMeta.standingFormLink && (
                  <h3>
                    <Button
                      buttonType="secondary"
                      size="xs"
                      className={bem('share')}
                      onClick={() => {
                        navigator.clipboard
                          .writeText(
                            `${brandMeta.link_url}/api/redirect/form/${roomSharingMeta.standingFormLink.code}`,
                          )
                          .then(
                            () => dispatch(setNotice('Link Copied!')),
                            err => console.error('Could not copy text: ', err),
                          );
                      }}
                    >
                      <FontAwesomeIcon icon={['fal', 'arrow-up-from-square']} />
                      Share
                    </Button>
                    {' - '}Sharing a standing form link with a(n) {portalType} will take
                    them to a similar basic form which they can fill out and submit. If
                    the Room is open then they will be able to view the Portal's Data Room
                    through that link as well. You can copy and share the same link with
                    multiple people and it will generate a new standing form each time
                    someone visits it.
                  </h3>
                )}
              </div>
            )}
            {filteredGroups.map(
              (group, index) =>
                !!group.groupLabel && (
                  <GroupCard
                    key={`group-${group.groupLabel || 'anonymous'}`}
                    group={group}
                    portalType={portalType}
                    filteredGroups={filteredGroups}
                    params={params}
                    project={project}
                    index={index}
                  />
                ),
            )}
          </div>
        </div>
        <InfoBar
          project={project}
          params={params}
          width={width}
          portalType={portalType}
          portalTypePlural={portalTypePlural}
          isGroupsEmpty={isGroupsEmpty}
        />
      </div>
      {isReopenShowing && (
        <AlertModal
          body={
            'This will discard any tasks and processes generated from your previous assessment.'
          }
          header={`Are you sure you want to restart the assessment '${assessmentLabel}'?`}
          isExclamationShowing
          isOpen={isReopenShowing}
          isWarning
          onClose={ev => setIsReopenShowing(false)}
          primaryActionText="Restart Assessment"
          onPrimaryClick={handleReopenAssessment}
        />
      )}
    </div>
  );
};

const infoBem = generateBem('portalInfoCard');
const InfoBar = ({
  isGroupsEmpty,
  project,
  params,
  width,
  portalType,
  portalTypePlural,
}) => {
  const dispatch = useDispatch();
  const { companyId, moduleId, transactionId } = params;
  const {
    rooms,
    id: projectId,
    template: { actions = [] },
    groupedTransactions,
    transactions,
  } = project;
  const { id: templateId } = actions[0] || {};
  const startedActionIndex = transactions.findIndex(
    e => !!e.open && e.template.id === templateId,
  );
  const anyActionIndex = transactions.findIndex(e => e.template.id === templateId);
  const { id } = transactions[startedActionIndex] || transactions[anyActionIndex] || {};

  // const { id: actionTemplateId, label, description } = actions[0] || {};

  const projectRoom = rooms.find(e => !e.group) || {};
  const { id: roomId, data_access_code, open, require_auth, strict } = projectRoom;

  const addActionLabel = `Add ${portalType}`;

  const roomDocuments = useSelector(State => getRoomDocuments(State, roomId));
  const roomLinks = useSelector(State => getRoomLinks(State, roomId));
  const brandMeta = useSelector(BrandMeta);
  const faqs = useSelector(state => getFaqsForProjectView(state.Project, projectId));
  const isWorkflowCounselShowing = useSelector(state =>
    IsWorkflowCounselShowing(state, transactionId),
  );
  const projectCounsel = useSelector(state => ProjectCounsel(state, projectId));
  const reviewInfo = useSelector(state =>
    getReviewValues(state.Transaction, transactionId),
  );
  const sharedForms = useSelector(state =>
    getNonOpenForms(state.Transaction, transactionId),
  );
  const projectDocuments = useSelector(State => DocumentsByProject(State, projectId));
  const roomSharingMeta = useSelector(State => getRoomSharingMeta(State, roomId));
  const {
    standingForms,
    standingFormLink,
    // standingFormLinkLabel,
    // standingFormsCompleted,
    // otherForms,
    // otherFormsCompleted,
    // totalDocInteractions,
    totalTimesVisited,
    // usersByEmail,
  } = roomSharingMeta;
  const roomAccessLabel =
    (!open && 'Private') ||
    (!!strict && 'Invite Only') ||
    (!!require_auth && 'Sign-in Required') ||
    'Open';
  const [isRoomFetched, setIsRoomFetched] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isStandingFormsModalOpen, setIsStandingFormsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  useEffect(() => {
    if (!isRoomFetched && !!roomId) {
      dispatch(fetchRoomCodes(roomId, companyId));
      dispatch(fetchRoomDocuments(roomId, companyId));
      dispatch(fetchRoomLinks(roomId, companyId));
      // dispatch(fetchRoomAccessLog(roomId));
      if (open) {
        dispatch(fetchRoomParticipants(roomId));
      }
      dispatch(fetchRoomHistory(data_access_code, roomId));
      setIsRoomFetched(true);
    }
  }, [dispatch, isRoomFetched, companyId, roomId, open, data_access_code, projectId]);

  const handleUpdateRoom = slug => {
    let body = {};
    let currentSlugValue =
      (slug === 'open' && open) ||
      (slug === 'require_auth' && require_auth) ||
      (slug === 'strict' && strict);
    body[slug] = !currentSlugValue;
    if (slug === 'open' && currentSlugValue) {
      body.init_room = true;
    }
    dispatch(updateRoom(body, roomId, companyId)).then(p =>
      dispatch(getAccountProject(projectId)),
    );
  };

  return (
    <div className={bem('section')}>
      {width > 1239 && (
        <div className={bem(['nav', 'nav--small'])}>
          <DropdownAction
            className={bem('dropdown')}
            isFlat
            right
            Toggle={toggleProps => (
              <Button size="sm" className={bem('share')} {...toggleProps}>
                <FontAwesomeIcon icon={['fal', 'arrow-up-from-square']} />
                Add {portalType}
                {!isGroupsEmpty && (
                  <TooltipAction
                    text={`If you already have a(n) ${portalType}'s basic info you can manually add them or batch upload multiple. This will generate tasks and deal rooms based around those ${portalTypePlural}.`}
                  />
                )}
              </Button>
            )}
          >
            <div className={infoBem('dropdownHeader')}>
              <h4>Add {portalType} Options</h4>
              <div className={infoBem('divider')} />
            </div>
            <Button
              buttonType="secondary"
              size="sm"
              to={handlePath(
                `/workbench/${moduleId}/${projectId}/${id || '0'}/add`,
                companyId,
              )}
            >
              {addActionLabel}
              {!isGroupsEmpty && (
                <TooltipAction
                  text={`If you already have a(n) ${portalType}'s basic info you can manually add them to generate tasks and a deal room based around that ${portalType}.`}
                />
              )}
            </Button>
            <Button
              buttonType="secondary"
              size="sm"
              to={handlePath(
                `/workbench/${moduleId}/${projectId}/${id || '0'}/batch-upload`,
                companyId,
              )}
            >
              Batch Upload {portalTypePlural}
              {!isGroupsEmpty && (
                <TooltipAction
                  text={`If you would rather batch upload multiple ${portalType}'s basic info at once then you can input their data into a csv template we provide to automatically generate tasks and a deal room for each ${portalType}.`}
                />
              )}
            </Button>
          </DropdownAction>
          <DropdownAction
            className={bem('dropdown')}
            right
            Toggle={toggleProps => (
              <Button
                buttonType="secondary"
                isDisabled={!roomId && !standingFormLink}
                size="sm"
                className={bem('share')}
                {...toggleProps}
              >
                <FontAwesomeIcon icon={['fal', 'arrow-up-from-square']} />
                Share
                {!isGroupsEmpty && (
                  <TooltipAction
                    text={`Link to standing form and Data Room. Can copy and share same link with multiple ${portalTypePlural} which will generate a new standing form each visit.`}
                  />
                )}
              </Button>
            )}
          >
            <div className={infoBem('dropdownHeader')}>
              <h4>Sharing Options</h4>
              <div className={infoBem('divider')} />
            </div>
            <Button
              buttonType="secondary"
              isDisabled={!standingFormLink}
              size="sm"
              onClick={() => {
                navigator.clipboard
                  .writeText(
                    `${brandMeta.link_url}/api/redirect/form/${standingFormLink.code}`,
                  )
                  .then(
                    () => dispatch(setNotice('Link Copied!')),
                    err => console.error('Could not copy text: ', err),
                  );
              }}
            >
              {roomId
                ? 'Copy Standing Form Link with Data Room'
                : 'Copy Standing Form Link'}
            </Button>
            <Button
              buttonType="secondary"
              size="sm"
              isDisabled={!roomId}
              onClick={() => {
                navigator.clipboard
                  .writeText(`${brandMeta.link_url}/data-room/${data_access_code}`)
                  .then(
                    () => dispatch(setNotice('Link Copied!')),
                    err => console.error('Could not copy text: ', err),
                  );
              }}
            >
              Copy Data Room Link
            </Button>
          </DropdownAction>
        </div>
      )}
      <div className={bem(['body', 'body--small'])}>
        <div className={infoBem()}>
          <div
            className={infoBem(['header', isOpen ? 'header--open' : null])}
            onClick={ev => {
              if (!ev.defaultPrevented && width < 1240) {
                setIsOpen(!isOpen);
              }
            }}
          >
            <FontAwesomeIcon className={infoBem('headerChevron')} icon="chevron-right" />
            <h2>
              Portal Info
              <Button
                buttonType="icon"
                className={infoBem('headerInfo')}
                size="xs"
                onClick={ev => {
                  ev.preventDefault();
                  setIsInfoModalOpen(!isInfoModalOpen);
                }}
              >
                <FontAwesomeIcon icon="info" />
              </Button>
            </h2>
            {width < 1240 && (
              <div className={infoBem('headerActions')}>
                <DropdownAction
                  className={bem('dropdown')}
                  right
                  Toggle={toggleProps => (
                    <Button
                      buttonType="secondary"
                      isDisabled={!roomId && !standingFormLink}
                      size="sm"
                      className={bem('share')}
                      {...toggleProps}
                    >
                      <FontAwesomeIcon icon={['fal', 'arrow-up-from-square']} />
                      Share
                    </Button>
                  )}
                >
                  <div className={infoBem('dropdownHeader')}>
                    <h4>Sharing Options</h4>
                    <div className={infoBem('divider')} />
                  </div>
                  <Button
                    buttonType="secondary"
                    isDisabled={!roomId}
                    disabledTooltip="No Data Room available"
                    size="sm"
                    onClick={ev => {
                      ev.preventDefault();
                      navigator.clipboard
                        .writeText(`${brandMeta.link_url}/data-room/${data_access_code}`)
                        .then(
                          () => dispatch(setNotice('Link Copied!')),
                          err => console.error('Could not copy text: ', err),
                        );
                    }}
                  >
                    Copy Data Room Link
                  </Button>
                  <Button
                    buttonType="secondary"
                    isDisabled={!standingFormLink}
                    disabledTooltip="No Data Room available"
                    size="sm"
                    onClick={ev => {
                      ev.preventDefault();
                      navigator.clipboard
                        .writeText(
                          `${brandMeta.link_url}/api/redirect/form/${standingFormLink.code}`,
                        )
                        .then(
                          () => dispatch(setNotice('Link Copied!')),
                          err => console.error('Could not copy text: ', err),
                        );
                    }}
                  >
                    {roomId
                      ? 'Copy Data Room with new Standing Form Link'
                      : 'Copy new Standing Form Link'}
                  </Button>
                </DropdownAction>
                <DropdownAction
                  className={bem('dropdown')}
                  isFlat
                  right
                  Toggle={toggleProps => (
                    <Button size="sm" {...toggleProps}>
                      <FontAwesomeIcon icon={['fal', 'arrow-up-from-square']} />
                      Add {portalType}
                    </Button>
                  )}
                >
                  <div className={infoBem('dropdownHeader')}>
                    <h4>Add {portalType} Options</h4>
                    <div className={infoBem('divider')} />
                  </div>
                  <Button
                    buttonType="secondary"
                    size="sm"
                    to={handlePath(
                      `/workbench/${moduleId}/${projectId}/${id || '0'}/add`,
                      companyId,
                    )}
                  >
                    {addActionLabel}
                    {!isGroupsEmpty && (
                      <TooltipAction
                        text={`If you already have a(n) ${portalType}'s basic info you can manually add them. This will generate tasks and a deal room based around that ${portalType}.`}
                      />
                    )}
                  </Button>
                  <Button
                    buttonType="secondary"
                    size="sm"
                    to={handlePath(
                      `/workbench/${moduleId}/${projectId}/${id || '0'}/batch-upload`,
                      companyId,
                    )}
                  >
                    Batch Upload {portalTypePlural}
                    {!isGroupsEmpty && (
                      <TooltipAction
                        text={`If you would rather batch upload multiple ${portalType}'s basic info at once then you can input their data into a csv template we provide to automatically generate tasks and a deal room for each ${portalType}.`}
                      />
                    )}
                  </Button>
                </DropdownAction>
              </div>
            )}
          </div>
          {(isOpen || width > 1239) && (
            <>
              <div className={infoBem('section')}>
                <div className={infoBem('sectionHeader')}>
                  <h4>Data Room</h4>
                  <div className={infoBem('divider')} />
                </div>
                {width > 1239 && (
                  <div className={infoBem('roomActions')}>
                    <Button
                      buttonType="icon"
                      isDisabled={!roomId}
                      size="sm"
                      to={`/data-room/${data_access_code}/settings`}
                      tooltip="Room Sharing & Access"
                    >
                      <FontAwesomeIcon icon={['fal', 'cog']} />
                    </Button>
                    <Button
                      buttonType="secondary"
                      isDisabled={!roomId}
                      disabledTooltip="No Data Room available"
                      size="sm"
                      to={`/data-room/${data_access_code}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={['fal', 'external-link']} /> View Data Room
                    </Button>
                  </div>
                )}
                <div className={infoBem(['sectionRow', 'sectionRow--flat'])}>
                  <div className={infoBem('roomItems')}>
                    {!!roomId && (
                      <>
                        <div className={infoBem('sectionCol')}>
                          <h4>Room Access</h4>
                          <DropdownAction
                            className={bem('dropdown')}
                            left
                            Toggle={toggleProps => (
                              <Button
                                isDisabled={!roomId}
                                buttonType="link"
                                className={infoBem('roomAccess')}
                                {...toggleProps}
                              >
                                <FontAwesomeIcon
                                  className={infoBem('lock')}
                                  icon={['fal', 'lock-keyhole']}
                                />
                                <h3>{roomAccessLabel}</h3>
                                {!!roomId && (
                                  <FontAwesomeIcon
                                    className={infoBem('chevron')}
                                    icon="chevron-down"
                                  />
                                )}
                              </Button>
                            )}
                          >
                            <div className={infoBem('dropdownHeader')}>
                              <h4>Access Options</h4>
                              <div className={infoBem('divider')} />
                            </div>
                            <Button
                              buttonType="secondary"
                              size="sm"
                              onClick={e => handleUpdateRoom('open')}
                            >
                              {open ? 'Close Room' : 'Open Room'}
                            </Button>
                            <Button
                              buttonType="secondary"
                              isDisabled={!open}
                              size="sm"
                              onClick={e => handleUpdateRoom('require_auth')}
                            >
                              {require_auth ? 'Sign-in Optional' : 'Sign-in Required'}
                            </Button>
                            <Button
                              buttonType="secondary"
                              isDisabled
                              size="sm"
                              onClick={e => handleUpdateRoom('strict')}
                            >
                              {strict ? 'Invite Optional' : 'Invite Only'}
                            </Button>
                          </DropdownAction>
                        </div>
                        <div className={infoBem('sectionCol')}>
                          <h4>Views</h4>
                          <h3>{totalTimesVisited}</h3>
                        </div>
                      </>
                    )}
                    {!roomId && (
                      <>
                        <div className={infoBem('sectionCol')}>
                          <h4>Employees</h4>
                          <h3>{groupedTransactions.length - 1}</h3>
                        </div>
                        <div className={infoBem('sectionCol')}>
                          <h4>Documents</h4>
                          <h3>{projectDocuments.length}</h3>
                        </div>
                      </>
                    )}
                    <div className={infoBem('sectionCol')}>
                      <h4>Standing Forms</h4>
                      <div
                        className={infoBem([
                          'roomAccess',
                          standingForms.length === 0 && 'roomAccess--flat',
                        ])}
                        onClick={() => {
                          if (standingForms.length > 0) {
                            setIsStandingFormsModalOpen(true);
                          }
                        }}
                      >
                        <h3>
                          {standingForms.length} Form
                          {standingForms.length === 1 ? '' : 's'}
                        </h3>
                        {standingForms.length > 0 && (
                          <FontAwesomeIcon
                            className={infoBem('chevron')}
                            icon={['fal', 'arrow-up-from-square']}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {width < 1240 && (
                    <div className={infoBem('roomActions')}>
                      <Button
                        buttonType="icon"
                        size="sm"
                        to={`/data-room/${data_access_code}/settings`}
                        tooltip="Room Sharing & Access"
                      >
                        <FontAwesomeIcon icon={['fal', 'cog']} />
                      </Button>
                      <Button
                        buttonType="secondary"
                        size="sm"
                        to={`/data-room/${data_access_code}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fal', 'external-link']} /> View Data Room
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              {roomDocuments.length > 0 && (
                <div className={infoBem('section')}>
                  <div className={infoBem('sectionHeader')}>
                    <h4>Documents</h4>
                    <div className={infoBem('divider')} />
                  </div>
                  <div
                    className={infoBem([
                      'sectionRow',
                      'sectionRow--flat',
                      'sectionRow--documents',
                    ])}
                  >
                    {roomDocuments.map(doc => (
                      <DocumentItem
                        doc={doc}
                        key={`group-doc-${doc.id}`}
                        params={params}
                      />
                    ))}
                  </div>
                </div>
              )}
              {roomLinks.length > 0 && (
                <div className={infoBem('section')}>
                  <div className={infoBem('sectionHeader')}>
                    <h4>Links</h4>
                    <div className={infoBem('divider')} />
                  </div>
                  {roomLinks.map(link => (
                    <a
                      className={infoBem('sectionRow')}
                      key={`room-link-${link.id}`}
                      href={
                        link.link.includes('http') ? link.link : 'https://' + link.link
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className={infoBem('sectionCol')}>
                        <h3>{link.label}</h3>
                        <h4>
                          <FontAwesomeIcon
                            className={infoBem('linkIcon')}
                            icon={['fal', 'link-simple']}
                          />
                          {link.link}
                        </h4>
                      </div>
                    </a>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <RoomStandingFormsModal
        account_id={companyId}
        isOpen={isStandingFormsModalOpen}
        isRoomOpen={!!open}
        onClose={() => setIsStandingFormsModalOpen(false)}
        standingForms={standingForms}
      />
      <WorkbenchInfoModal
        faqs={faqs}
        isOverview
        isWorkflowCounselShowing={isWorkflowCounselShowing}
        isPortalView
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        sharedForms={sharedForms}
        params={params}
        projectCounsel={projectCounsel}
        reviewInfo={reviewInfo}
      />
    </div>
  );
};

// const groupBem = generateBem('projectGroupCard');
const GroupCard = ({ filteredGroups, group, portalType, index, params, project }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(filteredGroups.length === 2);
  const [isRemoveGroupOpen, setIsRemoveGroupOpen] = useState(false);
  const [isUserHistoryModalOpen, setIsUserHistoryModalOpen] = useState(false);
  const [selectedUserHistory, setSelectedUserHistory] = useState({});

  const {
    createdAt,
    isGroupDeletable,
    groupLabel,
    transactions,
    transactionsOpen,
    groupDocuments,
  } = group;
  const { rooms, id: projectId, groupedTransactions } = project;
  const { companyId, moduleId } = params;

  const groupRoom = rooms.find(e => e.group === groupLabel);
  const {
    account_id,
    id: roomId,
    data_access_code,
    open,
    strict,
    require_auth,
  } = groupRoom || {};

  const accountId = account_id || companyId;
  const dealRoomSharingMeta = useSelector(State => getRoomSharingMeta(State, roomId));
  const brandMeta = useSelector(BrandMeta);
  const { totalTimesVisited, totalPrivateLinks, usersByEmail } = dealRoomSharingMeta;
  const isLastGroup = index + 1 === (groupedTransactions || []).length;
  const isRecipientsShowing =
    usersByEmail.length > 0 && !!usersByEmail.find(user => user.name !== 'Anonymous');
  const roomAccessLabel =
    (!open && 'Private') ||
    (!!strict && 'Invite Only') ||
    (!!require_auth && 'Sign-in Required') ||
    'Open';
  const isInitialTaskSubmitted = !!transactions[0]?.submission_date;
  const nextTransactionId = (transactions.find(t => t.open) || {}).id;
  const [isDealRoomFetched, setIsDealRoomFetched] = useState(false);

  useEffect(() => {
    if (!isDealRoomFetched && !!roomId && (filteredGroups.length < 8 || isOpen)) {
      setIsDealRoomFetched(true);
      dispatch(fetchRoomCodes(roomId, accountId));
      // dispatch(fetchRoomAccessLog(roomId, accountId));
      dispatch(fetchRoomParticipants(roomId, accountId));
      dispatch(fetchRoomHistory(data_access_code, roomId));
    }
  }, [
    dispatch,
    isDealRoomFetched,
    accountId,
    companyId,
    filteredGroups.length,
    isOpen,
    roomId,
    data_access_code,
  ]);

  const handleUpdateRoom = slug => {
    let body = {};
    let currentSlugValue =
      (slug === 'open' && open) ||
      (slug === 'require_auth' && require_auth) ||
      (slug === 'strict' && strict);
    body[slug] = !currentSlugValue;
    if (slug === 'open' && currentSlugValue) {
      body.init_room = true;
    }
    dispatch(updateRoom(body, roomId, accountId)).then(p =>
      dispatch(getAccountProject(projectId)),
    );
  };

  const handleRemoveGroup = () => {
    let isError = false;
    transactions.forEach((transaction, index) =>
      dispatch(performTaskAction('delete', transaction.id, projectId, 'Portal')).then(
        payload => {
          if (index + 1 === transactions.length && !isError) {
            dispatch(setNotice(`${groupLabel} removed from Project.`));
            dispatch(getAccountProject(projectId));
          }
        },
        error => {
          isError = true;
        },
      ),
    );
  };

  return (
    <div className={infoBem()}>
      <div
        className={infoBem(['header', isOpen ? 'header--open' : null])}
        onClick={ev => {
          if (!ev.defaultPrevented) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <FontAwesomeIcon className={infoBem('headerChevron')} icon="chevron-right" />
        <div className={infoBem('headerCol')}>
          <h2>
            {groupLabel}
            {transactionsOpen > 0 && (
              <span className={infoBem('headerAlert')}>{transactionsOpen}</span>
            )}
          </h2>
          <h4>Started: {dayjs(createdAt).format('L')}</h4>
        </div>
        <DropdownAction className={bem('dropdown')} left>
          <div className={infoBem('dropdownHeader')}>
            <h4>{groupLabel} Options</h4>
            <div className={infoBem('divider')} />
          </div>
          <Button
            buttonType="secondary"
            size="sm"
            to={handlePath(
              `/workbench/${moduleId}/${projectId}/${nextTransactionId}/portal-task`,
              accountId,
            )}
          >
            View {isInitialTaskSubmitted ? 'Next' : 'Initial'} Task
          </Button>
          <Button
            buttonType="secondary"
            isWarning
            disabledTooltip="Cannot delete after first task submission"
            isDisabled={!isGroupDeletable}
            size="sm"
            onClick={ev => {
              ev.preventDefault();
              setIsRemoveGroupOpen(true);
            }}
          >
            Remove {portalType}
          </Button>
        </DropdownAction>
      </div>
      {isOpen && (
        <>
          <div className={infoBem('section')}>
            <div className={infoBem('sectionHeader')}>
              <h4>Deal Room</h4>
              <div className={infoBem('divider')} />
            </div>
            <div className={infoBem(['sectionRow', 'sectionRow--flat'])}>
              <div className={infoBem('roomItems')}>
                <div className={infoBem('sectionCol')}>
                  <h4>Room Access</h4>
                  <DropdownAction
                    className={bem('dropdown')}
                    left
                    Toggle={toggleProps => (
                      <Button
                        buttonType="link"
                        className={infoBem('roomAccess')}
                        disabledTooltip="Room created after task submission"
                        isDisabled={!roomId}
                        {...toggleProps}
                      >
                        <FontAwesomeIcon
                          className={infoBem('lock')}
                          icon={['fal', 'lock-keyhole']}
                        />
                        <h3>{roomAccessLabel}</h3>
                        <FontAwesomeIcon
                          className={infoBem('chevron')}
                          icon="chevron-down"
                        />
                      </Button>
                    )}
                  >
                    <div className={infoBem('dropdownHeader')}>
                      <h4>Access Options</h4>
                      <div className={infoBem('divider')} />
                    </div>
                    <Button
                      buttonType="secondary"
                      size="sm"
                      onClick={e => handleUpdateRoom('open')}
                    >
                      {open ? 'Close Room' : 'Open Room'}
                    </Button>
                    <Button
                      buttonType="secondary"
                      isDisabled={!open}
                      size="sm"
                      onClick={e => handleUpdateRoom('require_auth')}
                    >
                      {require_auth ? 'Sign-in Optional' : 'Sign-in Required'}
                    </Button>
                    <Button
                      buttonType="secondary"
                      isDisabled
                      disabledTooltip="Not Currently Available"
                      size="sm"
                      onClick={e => handleUpdateRoom('strict')}
                    >
                      {strict ? 'Invite Optional' : 'Invite Only'}
                    </Button>
                  </DropdownAction>
                </div>
                <div className={infoBem('sectionCol')}>
                  <h4>Views</h4>
                  <h3>{totalTimesVisited}</h3>
                </div>
                <div className={infoBem('sectionCol')}>
                  <h4>Room Participants</h4>
                  <h3>{isRecipientsShowing ? usersByEmail.length : '0'}</h3>
                </div>
                <div className={infoBem('sectionCol')}>
                  <h4>Private Links</h4>
                  <h3>{totalPrivateLinks}</h3>
                </div>
              </div>
              <div className={infoBem('roomActions')}>
                <Button
                  buttonType="icon"
                  isDisabled={!roomId}
                  size="sm"
                  to={`/data-room/${data_access_code}/settings`}
                  tooltip="Room Sharing & Access"
                >
                  <FontAwesomeIcon icon={['fal', 'cog']} />
                </Button>
                <Button
                  buttonType="secondary"
                  disabledTooltip="Room created after task submission"
                  isDisabled={!roomId}
                  size="sm"
                  to={`/data-room/${data_access_code}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={['fal', 'external-link']} /> View Deal Room
                </Button>
              </div>
            </div>
          </div>
          {isRecipientsShowing && (
            <div className={infoBem('section')}>
              <div className={infoBem('sectionHeader')}>
                <h4>Room Participants</h4>
                <div className={infoBem('divider')} />
              </div>
              {usersByEmail.map(user => {
                const userDateLabel =
                  (user.lasVisited &&
                    `Last Visited: ${dayjs(user.lastVisited).format('L')}`) ||
                  (user.codes.length > 0 &&
                    `Date Invited: ${dayjs(user.created_at).format('L')}`) ||
                  '';
                if (user.name === 'Anonymous') {
                  return null;
                }
                return (
                  <div
                    className={infoBem('sectionRow')}
                    key={`group-user-${user.email}`}
                    onClick={ev => {
                      if (!ev.defaultPrevented) {
                        setSelectedUserHistory(user);
                        setIsUserHistoryModalOpen(true);
                      }
                    }}
                  >
                    <div className={infoBem('sectionCol')}>
                      <h3>{user.name}</h3>
                      <h4>
                        {user.email} | {userDateLabel}
                      </h4>
                    </div>
                    <div className={infoBem('roomActions')}>
                      <DropdownAction
                        className={bem('dropdown')}
                        left
                        Toggle={toggleProps => (
                          <Button
                            buttonType="link"
                            size="sm"
                            className={infoBem('roomAccess')}
                            {...toggleProps}
                          >
                            Share Personal Link
                            <FontAwesomeIcon icon={['fal', 'arrow-up-from-square']} />
                          </Button>
                        )}
                      >
                        <div className={infoBem('dropdownHeader')}>
                          <h4>Sharing Options</h4>
                          <div className={infoBem('divider')} />
                        </div>
                        <Button
                          buttonType="secondary"
                          size="sm"
                          isDisabled={user.codes.length === 0}
                          disabledTooltip="Share Link before you can copy."
                          onClick={() => {
                            navigator.clipboard
                              .writeText(
                                `${brandMeta.link_url}/data-room/${user.codes[0].code}`,
                              )
                              .then(
                                () => dispatch(setNotice('Link Copied!')),
                                err => console.error('Could not copy text: ', err),
                              );
                          }}
                        >
                          Copy Link
                        </Button>
                        <Button
                          buttonType="secondary"
                          size="sm"
                          onClick={() =>
                            dispatch(
                              addRoomCode(
                                { identifier: { type: 'email', value: user.email } },
                                user.room_id,
                                accountId,
                              ),
                            ).then(() => dispatch(setNotice('Link Emailed')))
                          }
                        >
                          Email Room Link
                        </Button>
                      </DropdownAction>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className={infoBem('section')}>
            <div className={infoBem('sectionHeader')}>
              <h4>Tasks</h4>
              <div className={infoBem('divider')} />
            </div>
            {transactions.map(transaction => (
              <PortalTaskRow
                key={`portal-transaction-${transaction.id}`}
                accountId={accountId}
                transaction={transaction}
                params={params}
              />
            ))}
          </div>
          {groupDocuments.length > 0 && (
            <div className={infoBem('section')}>
              <div className={infoBem('sectionHeader')}>
                <h4>Related Documents</h4>
                <div className={infoBem('divider')} />
              </div>
              <div
                className={infoBem([
                  'sectionRow',
                  'sectionRow--flat',
                  'sectionRow--documents',
                ])}
              >
                {groupDocuments.map(doc => (
                  <DocumentItem doc={doc} key={`group-doc-${doc.id}`} params={params} />
                ))}
              </div>
            </div>
          )}
        </>
      )}
      {!isLastGroup && <div className={infoBem('border')} />}
      {isRemoveGroupOpen && (
        <AlertModal
          body={`This will only remove ${groupLabel} from the portal, it will not delete any saved inputs about them from our database.`}
          header={`Remove ${groupLabel}?`}
          isExclamationShowing
          isOpen={isRemoveGroupOpen}
          isWarning
          primaryActionText="Remove"
          onClose={ev => setIsRemoveGroupOpen(false)}
          onPrimaryClick={handleRemoveGroup}
        />
      )}
      <RoomUserHistoryModal
        account_id={accountId}
        handleClose={() => setIsUserHistoryModalOpen(false)}
        isOpen={isUserHistoryModalOpen}
        roomId={roomId}
        roomUserHistory={selectedUserHistory || {}}
      />
    </div>
  );
};

const PortalTaskRow = ({ accountId, transaction, params }) => {
  const dispatch = useDispatch();
  const {
    label,
    id: transactionId,
    is_started,
    open,
    submission_date,
    validated_questionnaire,
    tasks,
  } = transaction;
  const { moduleId, projectId } = params;
  let statusLevel = 'low';
  let statusLabel = 'Get Started';
  if (!open) {
    statusLabel = 'Submitted';
    statusLevel = 'none';
  } else if (validated_questionnaire) {
    statusLabel = 'Form Completed';
    statusLevel = 'low';
  } else if (is_started) {
    statusLabel = 'Form Started';
  }

  let taskVariants = [];
  tasks.forEach(task => {
    if ((task.template_type?.variants || []).length > 1 && task.mode !== 'Upload') {
      taskVariants.push(task);
    }
  });

  const [isVariantsOpen, setIsVariantsOpen] = useState(false);

  return (
    <>
      <Link
        className={infoBem('sectionRow')}
        to={handlePath(
          `/workbench/${moduleId}/${projectId}/${transactionId}/portal-task`,
          accountId,
        )}
        onClick={ev => {
          if (ev.defaultPrevented) {
            ev.preventDefault();
            ev.stopPropagation();
          }
        }}
      >
        <div className={infoBem('sectionCol')}>
          <h3>
            {label}
            {taskVariants.length > 0 && !isVariantsOpen && !!open && (
              <>
                {' ('}
                <span
                  className={infoBem('changeTemplateAction')}
                  onClick={ev => {
                    ev.preventDefault();
                    setIsVariantsOpen(!isVariantsOpen);
                  }}
                >
                  {isVariantsOpen
                    ? 'Cancel'
                    : `Change Document Template${taskVariants.length > 1 ? 's' : ''}`}
                  <FontAwesomeIcon
                    icon={isVariantsOpen ? 'chevron-up' : 'chevron-down'}
                  />
                </span>
                )
              </>
            )}
          </h3>
          {!!submission_date && <h4>Completed: {dayjs(submission_date).format('L')}</h4>}
        </div>
        <div className={infoBem(['status', `status--${statusLevel}`])}>
          <p>{statusLabel}</p>
        </div>
      </Link>
      {isVariantsOpen &&
        taskVariants.map(task => (
          <Formik
            key={`task-variant-${task.id}`}
            initialValues={{ template_variant_id: task.template_variant.id }}
            onSubmit={({ template_variant_id }) => {
              dispatch(
                updateTaskVariant(task.id, { template_variant_id }, projectId),
              ).then(e => {
                if (validated_questionnaire && open) {
                  dispatch(
                    updateTransaction(transactionId, { validated_questionnaire: 0 }),
                  );
                }
                setIsVariantsOpen(false);
              });
            }}
          >
            {formikProps => (
              <Form className={infoBem('taskTemplatesWrapper')}>
                <div className={infoBem('taskTemplatesHeader')}>
                  <h3>
                    Choose a Template for{' '}
                    {task.template.type_label ||
                      task.template.type ||
                      taskVariants.template.label}
                    :
                  </h3>
                  <div className={infoBem('taskTemplatesActions')}>
                    <Button
                      buttonType="secondary"
                      onClick={() => setIsVariantsOpen(false)}
                      size="sm"
                    >
                      Cancel
                    </Button>
                    <Button buttonType="primary" type="submit" size="sm">
                      Use Selected Template
                    </Button>
                  </div>
                </div>
                <div className={infoBem('taskTemplates')}>
                  {task.template_type.variants.map((variant, index) => (
                    <div
                      className={infoBem('taskTemplate')}
                      key={`variant-${index}-${variant.id}`}
                    >
                      <InputStyledCheckbox
                        checked={variant.id === formikProps.values.template_variant_id}
                        label={variant.customer_label}
                        name={`${variant.id}-${index}`}
                        onChange={() => {
                          formikProps.setFieldValue('template_variant_id', variant.id);
                        }}
                        type="radio"
                        value={variant.id}
                      />
                      {variant.description && (
                        <h4 className={infoBem('taskTemplateHeader')}>
                          {'Description: '}
                          <p>{variant.description}</p>
                        </h4>
                      )}
                      {variant.partners.length > 0 && (
                        <h4 className={infoBem('taskTemplateHeader')}>
                          Partners:{' '}
                          <p>
                            {variant.partners
                              .reduce((dict, e) => [...dict, e.partner.name], [])
                              .join(', ')}
                          </p>
                        </h4>
                      )}
                      {!!variant?.preview && (
                        <PDFViewer
                          isLinkOnHover
                          file={{
                            isTemplate: true,
                            ...variant.preview,
                          }}
                          width={200}
                          height={258}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </Form>
            )}
          </Formik>
        ))}
    </>
  );
};

export const DocumentItem = ({
  accessCode,
  doc,
  documents,
  index,
  isEditing,
  params = {},
  roomId,
}) => {
  const dispatch = useDispatch();
  const companyBoxToken = useSelector(BoxToken);
  const brandMeta = useSelector(BrandMeta);

  const { projectId, transactionId } = params;
  const {
    account_id,
    box_file_id,
    filename,
    flat_features = [],
    file_token: { accessToken } = {},
    label: roomLabel,
    template_variant,
    panda_data,
    variants = [],
    task,
    room_document_id,
    status,
    isTransactionOpen,
    validatedQuestionnaire,
  } = doc;
  const {
    customer_label: variant_label,
    filename: variant_filename,
    preview: templatePreview,
    template_type,
    id: variantId,
  } = template_variant || {};
  const { id: taskId, mode } = task || {};
  const templatePages =
    (panda_data?.redline_data?.redlining_revisions || [])[0]?.pages || [];
  const { label: templateLabel, box_file_id: previewBoxFileId } = templatePreview || {};
  const { customer_label } = template_type || {};

  const documentTemplateLabel =
    roomLabel ||
    customer_label ||
    variant_label ||
    flat_features[0]?.feature_type?.question_label ||
    templateLabel;

  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const [isEditingDocLabel, setIsEditingDocLabel] = useState(false);
  const [isEditDocSubmitting, setIsEditDocSubmitting] = useState(false);
  const [docLabelInput, setDocLabelInput] = useState(documentTemplateLabel);
  const [isVariantsOpen, setIsVariantsOpen] = useState(false);

  const isVariantsShowing =
    !!transactionId && variants.length > 1 && isTransactionOpen && mode !== 'Upload';

  const boxToken = accessToken || companyBoxToken;

  const handleModalClose = e => {
    e.preventDefault();
    setIsOpen(false);
  };

  const filenameLabel = filename || variant_filename || 'Unknown name';
  const isFilenameShowing = !!filenameLabel && !filenameLabel.includes('[');

  const onDocumentDownload = ev => {
    ev.preventDefault();
    const dynamicUrl = accessCode
      ? `guest/rooms/${accessCode}/file/${box_file_id || previewBoxFileId}`
      : `account/box/file/${box_file_id || previewBoxFileId}`;

    let updatedUrl = `${brandMeta.web_base_url}/api/${dynamicUrl}`;
    var client = new XMLHttpRequest();
    client.open('GET', updatedUrl);
    client.withCredentials = true;
    client.responseType = 'blob';
    Object.entries(requestHeaders(true)).forEach(([key, value]) => {
      client.setRequestHeader(key, value);
    });
    client.onreadystatechange = () => {
      switch (client.readyState) {
        case 4:
          dispatch(setNotice('Starting Download.'));
          if (accessCode && box_file_id) {
            dispatch(
              createRoomHistory(
                accessCode,
                {
                  action: 'download_document',
                  box_file_id: box_file_id,
                  type: 'download',
                  label: `Viewed ${documentTemplateLabel}`,
                  sublabel: filenameLabel,
                },
                roomId,
              ),
            );
          }
          const url = window.URL.createObjectURL(client.response);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          break;
        default:
      }
    };
    client.send();
  };

  const handleBoxResponseInterceptor = ev => {
    if (ev.type === 'error' || ev instanceof Error) {
      setError('Unable to preview file at this time.');
    }
    return ev;
  };

  const handleSortUpClick = e => {
    e.preventDefault();
    dispatch(
      swapRoomDocuments(
        room_document_id,
        documents[index - 1].room_document_id,
        roomId,
        account_id,
        accessCode,
      ),
    );
  };

  const handleSortDownClick = e => {
    e.preventDefault();
    dispatch(
      swapRoomDocuments(
        room_document_id,
        documents[index + 1].room_document_id,
        roomId,
        account_id,
        accessCode,
      ),
    );
  };

  const handleRemoveDocument = e => {
    e.preventDefault();
    const message = `Are you sure you want to remove this document from the Data Room?`;
    if (window.confirm(message)) {
      dispatch(deleteRoomDocument(room_document_id, roomId, account_id, accessCode));
    }
  };

  const handleDocLabelUpdate = e => {
    setIsEditDocSubmitting(true);
    dispatch(
      updateRoomDocument(
        { label: docLabelInput },
        room_document_id,
        roomId,
        account_id,
        accessCode,
      ),
    ).then(
      e => {
        setIsEditDocSubmitting(false);
        setIsEditingDocLabel(false);
      },
      error => setIsEditDocSubmitting(false),
    );
  };

  const isPreviewAvailable =
    !!box_file_id || (!accessCode && (!!previewBoxFileId || templatePages.length > 0));

  return (
    <>
      <div
        className={infoBem([
          'documentWrapper',
          isPreviewAvailable ? 'documentWrapper--action' : null,
        ])}
        onClick={ev => {
          if (isPreviewAvailable) {
            if (!ev.defaultPrevented && !isEditingDocLabel) {
              ev.preventDefault();
              if (!isOpen && !!box_file_id && accessCode) {
                dispatch(
                  createRoomHistory(
                    accessCode,
                    {
                      action: 'view_document',
                      box_file_id,
                      type: 'view',
                      label: `Viewed ${documentTemplateLabel}`,
                      sublabel: filenameLabel,
                    },
                    roomId,
                  ),
                );
              }
              setIsOpen(!isOpen);
            }
          }
        }}
      >
        <div className={infoBem('documentPreview')}>
          {box_file_id && boxToken && !error && (
            <ContentPreview fileId={box_file_id} token={boxToken} />
          )}
          {!box_file_id && boxToken && previewBoxFileId && (
            <ReactPDFViewer boxFileId={previewBoxFileId} height={180} isThumbnail />
          )}
          {!box_file_id && !previewBoxFileId && templatePages.length > 0 && (
            <DocumentPreview
              pages={templatePages}
              isThumbnailShowing
              isLabelShowing={false}
            />
          )}
          {(!!error || !isPreviewAvailable) && (
            <DocPreviewSVG className={infoBem('previewSVG')} />
          )}
        </div>
        <h3 className={infoBem('docLabel')}>
          {!isEditingDocLabel && documentTemplateLabel}
          {!box_file_id && !accessCode && ' (Template)'}
          {(!!box_file_id || (!accessCode && !!previewBoxFileId)) && !isEditing && (
            <Button
              buttonType="icon"
              className={infoBem('download')}
              onClick={onDocumentDownload}
              size="sm"
            >
              <FontAwesomeIcon icon={['far', 'arrow-down-to-line']} />
            </Button>
          )}
          {isEditingDocLabel && (
            <InputStyledTextField
              className={infoBem('docLabelInput')}
              value={docLabelInput}
              isDisabled={isEditDocSubmitting}
              onChange={e => setDocLabelInput(e.target.value)}
              onBlur={e => handleDocLabelUpdate(e)}
            />
          )}
          {isEditing && !isEditingDocLabel && (
            <div className={infoBem('docEditActions')}>
              <Button
                buttonType="icon"
                size="sm"
                tooltip="Edit Document Label"
                tooltipAlign="right"
                onClick={e => {
                  e.preventDefault();
                  setIsEditingDocLabel(!isEditingDocLabel);
                }}
              >
                <FontAwesomeIcon icon={['fal', 'edit']} />
              </Button>
              {!!roomLabel && (
                <Button
                  buttonType="icon"
                  tooltip="Revert Label to default"
                  size="sm"
                  onClick={e => {
                    e.preventDefault();
                    dispatch(
                      updateRoomDocument(
                        { label: '' },
                        room_document_id,
                        roomId,
                        account_id,
                        accessCode,
                      ),
                    );
                  }}
                >
                  <FontAwesomeIcon icon={['fal', 'undo']} />
                </Button>
              )}
            </div>
          )}
        </h3>
        {!box_file_id && (
          <h4 className={infoBem('docStatus')}>
            <FontAwesomeIcon icon="exclamation-circle" />
            {PandaDocStatusDict(status, !!accessCode)}
          </h4>
        )}
        {isFilenameShowing && <h4>{filenameLabel}</h4>}
        {isVariantsShowing && (
          <h4>
            <Button
              buttonType="link"
              size="sm"
              onClick={ev => {
                ev.preventDefault();
                setIsVariantsOpen(true);
              }}
            >
              Change Template
              <FontAwesomeIcon icon={['far', 'file-import']} />
            </Button>
          </h4>
        )}
        {isEditing && (
          <div className={infoBem(['listActions', 'listActions--open'])}>
            <Button
              buttonType="icon"
              size="sm"
              isDisabled={index === 0}
              onClick={handleSortUpClick}
              tooltip="Move left"
            >
              <FontAwesomeIcon icon="arrow-left" />
            </Button>
            <Button
              buttonType="icon"
              size="sm"
              isDisabled={index + 1 === documents.length}
              onClick={handleSortDownClick}
              tooltip="Move right"
            >
              <FontAwesomeIcon icon="arrow-right" />
            </Button>
            <Button
              buttonType="icon"
              isWarning
              size="sm"
              onClick={handleRemoveDocument}
              tooltip="Remove Document"
            >
              <FontAwesomeIcon icon={['fal', 'trash-alt']} />
            </Button>
          </div>
        )}
      </div>
      {isVariantsShowing && (
        <MyModal
          className={infoBem('modal')}
          isOpen={isVariantsOpen}
          onRequestClose={() => setIsVariantsOpen(false)}
          overlayClassName={infoBem('modalOverlay')}
        >
          <FontAwesomeIcon
            className={infoBem('modalExit')}
            onClick={() => setIsVariantsOpen(false)}
            icon={['fal', 'times']}
          />
          <h2 className={infoBem('modalHeader')}>
            {documentTemplateLabel}
            {!box_file_id && '(Template)'}
          </h2>
          <Formik
            initialValues={{ template_variant_id: variantId }}
            onSubmit={({ template_variant_id }) => {
              dispatch(
                updateTaskVariant(taskId, { template_variant_id }, projectId),
              ).then(e => {
                if (validatedQuestionnaire && isTransactionOpen) {
                  dispatch(
                    updateTransaction(transactionId, { validated_questionnaire: 0 }),
                  );
                }
                setIsVariantsOpen(false);
              });
            }}
          >
            {formikProps => (
              <Form>
                <div className={infoBem('templatesHeader')}>
                  <h3>Choose a Template:</h3>
                </div>
                <div className={infoBem('templates')}>
                  {variants.map((variant, index) => (
                    <div
                      className={infoBem('template')}
                      key={`variant-${index}-${variant.id}`}
                    >
                      <InputStyledCheckbox
                        checked={variant.id === formikProps.values.template_variant_id}
                        label={variant.customer_label}
                        name={`${variant.id}-${index}`}
                        onChange={() => {
                          formikProps.setFieldValue('template_variant_id', variant.id);
                        }}
                        type="radio"
                        value={variant.id}
                      />
                      {variant.description && (
                        <h4 className={infoBem('templateHeader')}>
                          {'Description: '}
                          <p>{variant.description}</p>
                        </h4>
                      )}
                      {variant.partners.length > 0 && (
                        <h4 className={infoBem('templateHeader')}>
                          Partners:{' '}
                          <p>
                            {variant.partners
                              .reduce((dict, e) => [...dict, e.partner.name], [])
                              .join(', ')}
                          </p>
                        </h4>
                      )}
                      {!!variant?.preview && (
                        <PDFViewer
                          isLinkOnHover
                          file={{
                            isTemplate: true,
                            ...variant.preview,
                          }}
                          width={200}
                          height={258}
                        />
                      )}
                    </div>
                  ))}
                </div>
                <div className={infoBem('templatesActions')}>
                  <Button buttonType="secondary" onClick={() => setIsVariantsOpen(false)}>
                    Cancel
                  </Button>
                  <Button buttonType="primary" type="submit">
                    Use Selected Template
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </MyModal>
      )}
      <MyModal
        className={infoBem('modal')}
        isOpen={isOpen}
        onRequestClose={handleModalClose}
        overlayClassName={infoBem('modalOverlay')}
      >
        <FontAwesomeIcon
          className={infoBem('modalExit')}
          onClick={handleModalClose}
          icon={['fal', 'times']}
        />
        <h2 className={infoBem('modalHeader')}>
          {documentTemplateLabel}
          {!box_file_id && '(Template)'}
        </h2>
        <h4 className={infoBem('modalSubheader')}>
          {filenameLabel}
          {!!(box_file_id || previewBoxFileId) && (
            <Button buttonType="icon" onClick={onDocumentDownload} size="sm">
              <FontAwesomeIcon icon={['far', 'arrow-down-to-line']} />
            </Button>
          )}
        </h4>
        <div className={infoBem('modalPreview')}>
          {box_file_id && (
            <ContentPreview
              fileId={box_file_id}
              token={boxToken}
              responseInterceptor={handleBoxResponseInterceptor}
            />
          )}
          {!box_file_id && previewBoxFileId && (
            <ReactPDFViewer boxFileId={previewBoxFileId} height={540} showControls />
          )}
          {!box_file_id && !previewBoxFileId && templatePages.length > 0 && (
            <DocumentPreview
              pages={templatePages}
              isThumbnailShowing
              isLabelShowing={false}
              responseInterceptor={handleBoxResponseInterceptor}
            />
          )}
        </div>
      </MyModal>
    </>
  );
};

// const DocumentsPreviewer = ({ documents }) => {
//   const dispatch = useDispatch();
//   const collection = documents.map(doc => doc.box_file_id);
//   const boxToken = useSelector(BoxToken);
// const brandMeta = useSelector(BrandMeta);

//   const [collectionId, setCollectionId] = useState(documents[0].box_file_id);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const selectedDoc = documents.find(doc => doc.box_file_id === collectionId);
//   const {
//     box_file_id,
//     filename,
//     flat_features = [],
//     label: roomLabel,
//     template_variant,
//   } = selectedDoc || {};
//   const {
//     customer_label: variant_label,
//     filename: variant_filename,
//     preview: templatePreview,
//     template_type,
//   } = template_variant || {};
//   const { label: templateLabel, box_file_id: previewBoxFileId } = templatePreview || {};
//   const { customer_label } = template_type || {};

//   const documentTemplateLabel =
//     roomLabel ||
//     customer_label ||
//     variant_label ||
//     flat_features[0]?.feature_type?.question_label ||
//     templateLabel;
//   const filenameLabel = filename || variant_filename || 'Unknown name';

//   const onDocumentDownload = ev => {
//     ev.preventDefault();
//     const updatedUrl = `${brandMeta.web_base_url}/api/account/box/file/${box_file_id}`;
//     var client = new XMLHttpRequest();
//     client.open('GET', updatedUrl);
//     client.withCredentials = true;
//     client.responseType = 'blob';
//     Object.entries(requestHeaders(true)).forEach(([key, value]) => {
//       client.setRequestHeader(key, value);
//     });
//     client.onreadystatechange = () => {
//       switch (client.readyState) {
//         case 4:
//           dispatch(setNotice('Starting Download.'));
//           const url = window.URL.createObjectURL(client.response);
//           const link = document.createElement('a');
//           link.href = url;
//           link.setAttribute('download', filename);
//           document.body.appendChild(link);
//           link.click();
//           document.body.removeChild(link);
//           break;
//         default:
//       }
//     };
//     client.send();
//   };

//   return (
//     <>
//       <div
//         className={infoBem('documentWrapper')}
//         onClick={ev => {
//           if (
//             !(ev.target?.className || '').includes('bcpr-navigate') &&
//             !ev.defaultPrevented
//           ) {
//             ev.preventDefault();
//             ev.stopPropagation();
//             setIsModalOpen(!isModalOpen);
//           }
//         }}
//       >
//         <div className={infoBem('documentPreview')}>
//           <ContentPreview
//             fileId={box_file_id || previewBoxFileId}
//             onNavigate={nextFileId => {
//               setCollectionId(nextFileId);
//             }}
//             collection={collection}
//             token={boxToken}
//           />
//         </div>
//         <h3>
//           {documentTemplateLabel}
//           <Button
//             buttonType="icon"
//             className={infoBem('download')}
//             onClick={onDocumentDownload}
//             size="sm"
//           >
//             <FontAwesomeIcon icon={['far', 'arrow-down-to-line']} />
//           </Button>
//         </h3>
//         <h4>{filenameLabel}</h4>
//       </div>
//       <DocumentsPreviewerModal
//         boxToken={boxToken}
//         documents={documents}
//         isModalOpen={isModalOpen}
//         setIsModalOpen={setIsModalOpen}
//       />
//     </>
//   );
// };

// const DocumentsPreviewerModal = ({
//   boxToken,
//   documents,
//   isModalOpen,
//   setIsModalOpen,
// }) => {
//   const collection = documents.map(doc => doc.box_file_id);

//   const [collectionId, setCollectionId] = useState(documents[0].box_file_id);

//   const selectedDoc = documents.find(doc => doc.box_file_id === collectionId);
//   const {
//     box_file_id,
//     filename,
//     flat_features = [],
//     label: roomLabel,
//     template_variant,
//   } = selectedDoc || {};
//   const {
//     customer_label: variant_label,
//     filename: variant_filename,
//     preview: templatePreview,
//     template_type,
//   } = template_variant || {};
//   const { label: templateLabel, box_file_id: previewBoxFileId } = templatePreview || {};
//   const { customer_label } = template_type || {};

//   const handleModalClose = e => {
//     e.preventDefault();
//     setIsModalOpen(false);
//     setCollectionId(documents[0].box_file_id);
//   };

//   const documentTemplateLabel =
//     roomLabel ||
//     customer_label ||
//     variant_label ||
//     flat_features[0]?.feature_type?.question_label ||
//     templateLabel;
//   const filenameLabel = filename || variant_filename || 'Unknown name';
//   return (
//     <MyModal
//       className={infoBem('modal')}
//       isOpen={isModalOpen}
//       onRequestClose={handleModalClose}
//       overlayClassName={infoBem('modalOverlay')}
//     >
//       <FontAwesomeIcon
//         className={infoBem('modalExit')}
//         onClick={handleModalClose}
//         icon={['fal', 'times']}
//       />
//       <h2 className={infoBem('modalHeader')}>{documentTemplateLabel}</h2>
//       <h4>{filenameLabel}</h4>
//       <div className={infoBem('modalPreview')}>
//         <ContentPreview
//           fileId={box_file_id || previewBoxFileId}
//           onNavigate={nextFileId => {
//             setCollectionId(nextFileId);
//           }}
//           collection={collection}
//           token={boxToken}
//         />
//       </div>
//     </MyModal>
//   );
// };

export default PortalView;
