import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PageLoader from '../../components/PageLoader';
import { SavviNavLinkBar } from '../../components/SavviNavLink';
import TodosSection from '../../components/TodosSection';
import DocumentsSection from '../../components/DocumentsSection';
import ModuleDict from '../../utils/ModuleDict';
import { Button } from '../../components/common';
import TasksSection from '../../components/TasksSection';
import { hashContains, noHash } from '../../utils/generateMultiHash';

import './Home.scss';
import handlePath from '../../utils/handlePath';

const Home = ({
  companyId,
  hash,
  homeNavLinkItems,
  initialized,
  isAllEmpty,
  isDataLoaded,
  moduleId,
  myDocuments,
  filterInput,
  myTasks,
  myTodos,
  setFilterInput,
}) => (
  <div className="dashboard__content">
    <div className="dashboard__inner">
      <label className="dashboard__search">
        <FontAwesomeIcon className="dashboard__searchIcon" icon={['fal', 'search']} />
        <input
          autoComplete="off"
          placeholder="Search Dashboard"
          value={filterInput}
          name="workflowsFilter"
          onChange={e => setFilterInput(e.target.value)}
        />
        {(filterInput || '').length > 0 && (
          <FontAwesomeIcon
            className="dashboard__searchClear"
            icon={['fal', 'times']}
            onClick={() => setFilterInput('')}
          />
        )}
      </label>
      <SavviNavLinkBar
        navLinkItems={homeNavLinkItems}
        parentPathname={ModuleDict[moduleId].path}
        passedState={{ initialized, filterInput }}
      />
      {!isDataLoaded && <PageLoader />}
      {isDataLoaded && (
        <>
          {isAllEmpty && (
            <h3 className="dashboard__empty">
              No Action Items Found. Begin a new
              <Button
                buttonType="link"
                to={handlePath(ModuleDict['workflows'].path, companyId)}
              >
                Workflow
              </Button>
              or view your
              <Button
                buttonType="link"
                to={handlePath(ModuleDict['tasks'].path, companyId)}
              >
                Tasks
              </Button>
            </h3>
          )}
          {(noHash(hash) || hashContains(hash, '#actionable-documents')) &&
            myDocuments.length > 0 && (
              <DocumentsSection
                isAdmin
                isWarning
                moduleId={moduleId}
                sectionLabel="Documents Requiring Action"
                documents={myDocuments}
                isPdfHiding={myDocuments.length > 6}
              />
            )}
          {(noHash(hash) || hashContains(hash, '#to-dos')) && myTodos.length > 0 && (
            <TodosSection todos={myTodos} />
          )}
          {(noHash(hash) || hashContains(hash, '#open-tasks')) && myTasks.length > 0 && (
            <TasksSection
              isHome
              isSectionOpenInit={false}
              isHeightUnset
              moduleId={moduleId}
              sectionLabel="Open Tasks"
              transactions={myTasks}
            />
          )}
        </>
      )}
    </div>
  </div>
);

Home.propTypes = {
  actionableDocs: PropTypes.array,
  isDataLoaded: PropTypes.bool,
  isLoadingInitTimeout: PropTypes.bool,
  location: PropTypes.object,
  moduleId: PropTypes.string,
  homeNavLinkItems: PropTypes.array,
  todos: PropTypes.array,
};

export default Home;
